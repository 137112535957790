<template>
    <div class="auth-wrapper auth-v2">
        <b-row class="auth-inner m-0">
            <!-- Brand logo-->
            <!-- <b-link class="brand-logo">
        <Logo />
      </b-link> -->
            <!-- /Brand logo-->

            <!-- Left Text-->
            <b-col lg="7" class="d-none d-lg-flex align-items-center p-5">
                <div
                    class="
                        w-100
                        d-lg-flex
                        align-items-center
                        justify-content-center
                        px-5
                    "
                >
                    <b-img
                        fluid
                        src="/illustrations/login.svg"
                        alt="Enston Secretary Login Illustrations"
                        style="width: 55%;"
                    />
                </div>
            </b-col>
            <!-- /Left Text-->

            <!-- Login-->
            <b-col lg="5" class="d-flex align-items-center auth-bg px-2 p-lg-5">
                <b-col sm="8" md="6" lg="12" class="px-xl-2 mx-auto">
                    <b-card-title title-tag="h2" class="font-weight-bold mb-1">
                        Log in to Enston Secretary
                    </b-card-title>
                    <b-card-text class="mb-2">
                        Please login to your existing account.
                    </b-card-text>

                    <!-- form -->
                    <validation-observer ref="loginValidation">
                        <b-form class="auth-login-form mt-2" @submit.prevent>
                            <!-- email -->
                            <b-form-group label="Email" label-for="login-email">
                                <validation-provider
                                    #default="{ errors }"
                                    name="email"
                                    rules="required|email"
                                    mode="lazy"
                                >
                                    <b-form-input
                                        id="login-email"
                                        v-model="form.email"
                                        autofocus
                                        name="login-email"
                                        placeholder="john@example.com"
                                        :state="
                                            errors.length > 0 ? false : null
                                        "
                                    />
                                    <small class="text-danger">{{
                                        errors[0]
                                    }}</small>
                                </validation-provider>
                            </b-form-group>

                            <!-- forgot password -->
                            <b-form-group>
                                <div class="d-flex justify-content-between">
                                    <label for="login-password">Password</label>
                                    <b-link
                                        :to="{
                                            name: 'auth-forgot-password-v2',
                                        }"
                                        tabindex="-1"
                                    >
                                        <small>Forgot Password?</small>
                                    </b-link>
                                </div>

                                <validation-provider
                                    #default="{ errors }"
                                    name="password"
                                    rules="required"
                                >
                                    <b-input-group
                                        class="input-group-merge"
                                        :class="
                                            errors.length > 0
                                                ? 'is-invalid'
                                                : null
                                        "
                                    >
                                        <b-form-input
                                            id="login-password"
                                            v-model="form.password"
                                            :state="
                                                errors.length > 0 ? false : null
                                            "
                                            class="form-control-merge"
                                            :type="passwordFieldType"
                                            name="login-password"
                                            placeholder="············"
                                        />

                                        <b-input-group-append is-text>
                                            <feather-icon
                                                class="cursor-pointer"
                                                :icon="passwordToggleIcon"
                                                @click="
                                                    togglePasswordVisibility
                                                "
                                            />
                                        </b-input-group-append>
                                    </b-input-group>
                                    <small class="text-danger">{{
                                        errors[0]
                                    }}</small>
                                </validation-provider>
                            </b-form-group>

                            <ul class="backend-errors p-0 m-0">
                                <li
                                    v-for="(errors, index) in backendErrors"
                                    :key="index"
                                    class="
                                        alert alert-danger
                                        m-0
                                        p-1
                                        list-unstyled
                                    "
                                >
                                    <span v-if="Array.isArray(errors)">
                                        <span
                                            v-for="(
                                                error, innerIndex
                                            ) in errors"
                                            :key="innerIndex"
                                        >
                                            {{ error }}
                                        </span>
                                    </span>

                                    <span v-else>
                                        {{ errors }}
                                    </span>
                                </li>
                            </ul>

                            <!-- checkbox -->
                            <b-form-group>
                                <b-form-checkbox
                                    id="remember-me"
                                    v-model="status"
                                    name="checkbox-1"
                                >
                                    Remember Me
                                </b-form-checkbox>
                            </b-form-group>

                            <!-- submit buttons -->
                            <b-button
                                type="submit"
                                variant="primary"
                                block
                                :disabled="loading"
                                data-test="login"
                                class="
                                    d-flex
                                    justify-content-center
                                    align-items-center
                                "
                                @click="validationForm"
                            >
                                <span
                                    v-if="loading"
                                    class="
                                        d-flex
                                        justify-content-center
                                        next-button
                                    "
                                >
                                    Logging In
                                    <normal-loader class="ml-.5" />
                                </span>

                                <span v-else>Login</span>
                            </b-button>
                        </b-form>
                    </validation-observer>

                    <b-card-text class="text-center mt-2">
                        <span>New on our platform? </span>
                        <br>

                        <b-link :to="{ name: 'register' }" class="mt-.5 d-block" style="text-decoration: underline">
                            <span>Create an account</span>
                        </b-link>
                    </b-card-text>

                    <!-- divider -->
                    <div class="divider my-2">
                        <div class="divider-text">or</div>
                    </div>

                    <!-- social buttons -->
                    <div class="d-flex justify-content-center">
                        <a
                            type="button"
                            href="#"
                            :class="['d-flex justify-content-center btn btn-lg btn-block btn-white login-with-google-btn', {'disabled': isGoogleLoading}]"
                            :disabled="isGoogleLoading"
                            @click="AuthProvider('google')"
                        >
                            <img src="/logo/google.svg" alt="Google Logo" class="mr-1" >
                            <span>
                                Login with Google
                            </span>
                            <normal-loader v-if="isGoogleLoading" class="ml-2" />
                        </a>
                    </div>
                </b-col>
            </b-col>
            <!-- /Login-->
        </b-row>
    </div>
</template>

<script>
/* eslint-disable global-require */
import { ValidationProvider, ValidationObserver } from "vee-validate";
// import Logo from '@core/layouts/components/Logo.vue';
import {
    BRow,
    BCol,
    BLink,
    BFormGroup,
    BFormInput,
    BInputGroupAppend,
    BInputGroup,
    BFormCheckbox,
    BCardText,
    BCardTitle,
    BImg,
    BForm,
    BButton,
} from "bootstrap-vue";
import { required, email } from "@validations";
import { togglePasswordVisibility } from "@core/mixins/ui/forms";
// import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import { mapActions } from "vuex";
import store from "@/store";

export default {
    components: {
        BRow,
        BCol,
        BLink,
        BFormGroup,
        BFormInput,
        BInputGroupAppend,
        BInputGroup,
        BFormCheckbox,
        BCardText,
        BCardTitle,
        BImg,
        BForm,
        BButton,
        // Logo,
        ValidationProvider,
        ValidationObserver,
    },

    mixins: [togglePasswordVisibility],

    data() {
        return {
            status: "",

            form: {
                email: "",
                password: "",
            },

            sideImg: require("@/assets/images/pages/login-v2.svg"),

            // validation rulesimport store from '@/store/index'
            required,
            email,
            loading: false,
            isGoogleLoading: false,

            backendErrors: [],
        };
    },

    computed: {
        passwordToggleIcon() {
            return this.passwordFieldType === "password"
                ? "EyeIcon"
                : "EyeOffIcon";
        },

        imgUrl() {
            if (store.state.appConfig.layout.skin === "dark") {
                // eslint-disable-next-line vue/no-side-effects-in-computed-properties
                this.sideImg = require("@/assets/images/pages/login-v2-dark.svg");
                return this.sideImg;
            }
            return this.sideImg;
        },
    },

    methods: {
        ...mapActions({
            login: "auth/login",
            fetchUser: "auth/me",
            googleLogin: "auth/googleLogin",

        }),

        validationForm() {
            this.loading = true;
            this.backendErrors = [];

            this.$refs.loginValidation.validate().then(success => {
                if (success) {
                    this.submit().catch(e => {
                        let message = e.response
                            ? e.response.data.errors
                            : e.message;

                        if (message === "Network Error") {
                            message = "Web server is down.";
                        } else {
                            message = Object.values(message);
                            message = message.flat();
                        }

                        this.backendErrors.push(message);
                        this.loading = false;
                    });
                } else {
                    this.loading = false;
                }
            });
        },

        AuthProvider(provider) {
            this.isGoogleLoading = true;
            const self = this;
            this.$auth.authenticate(provider).then(response =>{
                self.socialLogin(response);
            }).catch(err => {
                console.error({err});
                this.isGoogleLoading = false;
            });

        },

        async socialLogin(response){
            const result = await this.googleLogin(response);
            this.isGoogleLoading = false;

            this.setUpData(result);
        },

        async submit() {
            const response = await this.login(this.form);
            this.setUpData(response);
        },

        setUpData(response) {
            this.fetchUser();

            this.$store.commit('auth/SET_AUTHENTICATED', true);
            // this.$store.commit('campaign/UPDATE_CAMPAIGN_NAME', response.campaign.name ?? "");

            if (this.$store.getters['auth/user'] && this.$store.getters['auth/user'].is_admin)
            {
                this.$store.commit('auth/UPDATE_VERIFIED_STATUS', true);
            }

            if (this.$store.getters['auth/user'] && this.$store.getters['auth/user'].email_verified_at)
            {
                this.$store.commit('auth/UPDATE_VERIFIED_STATUS', true);
            }

            if (
                response &&
            response.error === "Your email address is not verified."
            ) {
                this.$router.push({name: 'verifyEmail'});
            } else {
                this.$router.push("/");
            }
        },


    },
};
</script>

<style lang="scss">
@import "@core/scss/vue/pages/page-auth.scss";

.login-with-google-btn {
    font-weight: 600;
    font-size: 12px;
    line-height: 24px;
    padding: 0.3rem 0.8rem;
    letter-spacing: 0.5px;
    padding: 0.55rem 1.5rem;
    font-size: 12px;
    transition: all 0.6s;
    box-shadow: 0 1px 2px rgb(0 0 0 / 8%), 0 1px 4px rgb(0 0 0 / 12%);
    color: #6d7780;
    display: block;
    width: 100%;

    .preloader div:before {
        background-color: var(--primary-color);
    }

    .disabled {
        opacity: 0.5;
        &:hover {
            cursor: not-allowed;
        }
    }
}

.login-with-google-btn:hover {
    border-color: transparent !important;
    background-color: white;
    color: #6d7780;
    box-shadow: 0 0.5rem 1rem rgb(0 0 0 / 15%) !important;
}

.next-button .preloader {
    left: 50%;
}
</style>
